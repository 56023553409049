import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";
import { UserFragment } from "./UserFragment.ts";

const GetMeQuery = graphql(`
  query me {
    me {
      ...UserFragment
    }
  }
`);
export function useMe(skip = false) {
  const { data, loading, refetch, error } = useQuery(GetMeQuery, {
    skip,
    fetchPolicy: "network-only",
  });

  return {
    user: getFragmentData(UserFragment, data?.me),
    loading,
    refetch,
    error,
  };
}
