import { Card } from "./Card.tsx";
import { PrimaryButton } from "./Button/PrimaryButton.tsx";

export function ServerErrorScreen() {
  return (
    <div
      className={
        " flex-1 min-h-screen min-w-screen flex flex-col items-center justify-center p-4"
      }
    >
      <div className={"max-w-[400px]"}>
        <Card>
          <h1 className={"font-bold text-2xl"}>
            Oh no, qualcosa è andato storto! 🛠
          </h1>
          <p>
            Sembra che i nostri server abbiano bisogno di un po’ di attenzione.
            Stiamo già lavorando per risolvere il problema il più velocemente
            possibile. 🚀
          </p>
          <div className={"space-y-1"}>
            <h3 className={"font-semibold"}>
              Ecco cosa puoi fare nel frattempo:
            </h3>

            <ul>
              <li>
                - <span className={"font-semibold"}>Riprova più tardi:</span> La
                maggior parte dei problemi si risolvono entro pochi minuti.
              </li>
              <li>
                - <span className={"font-semibold"}>Aggiorna la pagina:</span>{" "}
                Magari il problema si è già sistemato!
              </li>
            </ul>
          </div>
          <p>
            Se il problema persiste, ci dispiace tantissimo! Puoi contattarci a{" "}
            <a
              className={"text-blue-400 underline"}
              href={"mailto:help@normasteaching.com"}
            >
              help@normasteaching.com
            </a>
            , ma ti assicuriamo che siamo già all’opera. 💪 Grazie per la
            pazienza! 🙏
          </p>
          <PrimaryButton
            label={"Aggiorna la pagina"}
            onClick={() => window.location.reload()}
          />
        </Card>
      </div>
    </div>
  );
}
